import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col, Img } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../components/ArticleCard/ArticleCard.scss';

const Tags = (props) => {
  const posts = props.data.allMarkdownRemark.edges;
  const { tag } = props.pageContext;
  return (
    <>
      <HeaderExpanded title={`Posts Tagged: ${tag}`} />
      <Container className='my-3'>
        <Row>
          {posts.map(({ node }, i) => (
            <Col key={i} lg={6} className='my-3'>
              <div className='hp-article-card'>
                <Link to={node.fields.slug} key={i} className='hp-article'>
                  <Container>
                    <Row className='py-3'>
                      <Col xs={12} md={11}>
                        <h1 className='hp-article-title'>
                          {node.frontmatter.title}
                        </h1>
                        <p>{node.excerpt}</p>
                        <small>{node.frontmatter.date}</small>
                      </Col>
                      <Col
                        xs={1}
                        className='align-items-center justify-content-center d-none d-md-flex'
                      >
                        <FontAwesomeIcon size='2x' icon={faChevronRight} />
                      </Col>
                    </Row>
                  </Container>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Tags;

export const query = graphql`
  query TagsQuery($tag: String!) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            title
            image {
              childImageSharp {
                resize(width: 400, height: 320) {
                  src
                }
                fluid(maxWidth: 520) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
